import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import Navbar from '../../Components/commonComponents/navbar/Navbar'
import Sidebar from '../../Components/commonComponents/sidebar/Sidebar'
import UserProfile from '../UserProfile/UserProfile'
import AssignedListings from "../AssignList/AssignedListings/AssignedListings"
import ManagerSubscription from '../ManageSubscriptions/ManagerSubscription/ManagerSubscription'
import ManageRequestList from '../ManageRequest/ManageRequestList/ManageRequestList'
import PropertyOwnerList from '../PropertyOwner/PropertyOwnerList/PropertyOwnerList'
import PaymentCardDetail from '../PaymentOption/PaymentCardDetail/PaymentCardDetail'
import ChatList from '../ChatSeaction/ChatList/ChatList'
import "./Dashboard.scss"
import SideBarMobile from '../../Components/commonComponents/SideBarMobile/SideBarMobile'

const Dashboard = () => {
  const params = useParams()
  const [myContactModal, setMyContactModal] = useState(false)
  const [mobileModal, setMobileModal] = useState(false)
  const [contactModal, setContactModal] = useState(false)
  const [toogleSideModal, setToggleSideModal] = useState(false)

  const handleGetOpenModal = (data) => {
    setMyContactModal(data)
  }

  const handleGetModal = (value = false) => {
    setMobileModal(value)
  }
  const handleFilterModal = (val) => {
    setContactModal(val)
  }

  const handleToggleButton = (val) => {
    setToggleSideModal(val)
  }

  return (
    <>
      <div style={{ background: '#f2f2f7', height: 'fit-content' }}>
        <Navbar handleToggleButton={(val) => handleToggleButton(val)} />
        <div className='d-flex'>
          <div className="Sidebar_control side-resposne">
            <Sidebar />
          </div>
          <div className={params.value === "PaymentOptions" ? "Sidebar_control side-value-pay" : params.value === "Chats" ? "Sidebar_control side-value-list" : params.value === "PropertyOwners" ? "Sidebar_control side-value-list" : "Sidebar_control side-value-component"}>
            <div style={{ display: (params.value !== "PaymentOptions" && params.value !== "ManageSubscription" && params.value !== "userProfile" && params.value !== "AssignedListings" && params.value !== "ManagingRequests" && params.value !== "Chats" && params.value !== "PropertyOwners" && 'inline-block'), marginTop: '60px', marginBottom: (params.value !== "Chats" && '5.5vh') }}>
              {
                params.value === "AssignedListings" &&
                <AssignedListings />
              }
              {
                params.value === "ManagingRequests" &&
                <ManageRequestList />
              }
              {
                params.value === "PropertyOwners" &&
                <PropertyOwnerList />
              }
              {
                params.value === "Chats" &&
                <ChatList />
              }
              {
                params.value === "userProfile" &&
                <UserProfile />
              }
              {
                params.value === "PaymentOptions" &&
                <PaymentCardDetail />
              }
              {params.value === "ManageSubscription" &&
                <ManagerSubscription />
              }
            </div>
          </div>
        </div>
        <div className="Sidebar_control side-mobile-responsive">
          <SideBarMobile getModal={(val) => handleGetModal(val)} toogleSideModal={toogleSideModal} />
        </div>
      </div>
    </>
  )
}

export default Dashboard
