import React, { useState } from 'react';
import styled from 'styled-components';
import { Col, Row } from 'react-bootstrap';
import SwipeableViews from 'react-swipeable-views';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import { autoPlay } from 'react-swipeable-views-utils';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Reply from "../../../../assets/images/reply.png";
import Bedroom from "../../../../assets/images/favoriteProperty/bedroom.svg"
import Bathroom from "../../../../assets/images/favoriteProperty/bathroom.svg"
import Parking from "../../../../assets/images/favoriteProperty/parking.svg"
import Clock from "../../../../assets/images/favoriteProperty/clock.svg"
import Home from "../../../../assets/images/favoriteProperty/home.svg"
import Furnishing from "../../../../assets/images/favoriteProperty/furnishing.svg"
import ArrowForward from "../../../../assets/images/listingManager/Arrow-property.svg"
import "./DetailWithOwnerList.scss"

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const CustomImage = styled.img`
// background:url('${props => props.image}');
// height: 220px;
// width:100%;
// background-size: cover;
// background-repeat: no-repeat;
// background-position: center center;

//     @media (min-width:770px) and (max-width: 1199px) {
//      height: 182px;
//    }
      
//    @media (max-width:768px) {
//    height: 142px;
//   } 

background:url('${props => props?.image}');
height: 182px;
width:100%;
background-size: cover;
// background-repeat: no-repeat;
// background-position: center center;

@media (min-width:915px) and (max-width: 1199px) {
     height: 162px;
   }

   @media (min-width: 820px) and (max-width: 915px) {
     height: 162px;
   }
      
 @media (min-width:575px) and  (max-width:768px) {
   height: 142px;
  }

   @media (min-width:431px) and  (max-width:575px) {
   height: 320px;
  }

   @media  (min-width:400px) and  (max-width:431px) {
   height: 220px;
  }

   @media (max-width:400px) {
   height: 190px;
  }
`

const DetailOwnerContainer = styled.div`
font-family: Proxima_nova_reg;
// width:800px;
height: auto;
background: white;
box-shadow: 0px 2px 8px 0px #0000001F;
`;

const useStyles = makeStyles((theme) => ({
    rootValue: {
        maxWidth: 400,
        flexGrow: 1,
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        height: 50,
        paddingLeft: theme.spacing(4),
        backgroundColor: theme.palette.background.default,
    },
    img: {
        height: 210,
        display: 'block',
        maxWidth: 300,
        overflow: 'hidden',
        width: '100%',
    },
}));

const DetailWithOwnerList = (props) => {
    const classes = useStyles();
    const moment = require('moment-timezone');
    const theme = useTheme();
    const [activeStep, setActiveStep] = useState(0);
    const maxSteps = props.sliderImage.length;
    const [screenSize, getDimension] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });


    const handleNext = (data, galleryIndex) => {
        setActiveStep((prevSteps) => ({
            ...prevSteps,
            [data?.propertyMaster_id]: prevSteps[data?.propertyMaster_id] === data?.property_images?.length - 1
                ? 0
                : (prevSteps[data?.propertyMaster_id] || 0) + 1
        }));
    };

    const handleBack = (data, galleryIndex) => {
        setActiveStep((prevSteps) => ({
            ...prevSteps,
            [data?.propertyMaster_id]: prevSteps[data?.propertyMaster_id] === 0
                ? data?.property_images?.length - 1
                : (prevSteps[data?.propertyMaster_id] || data?.property_images?.length) - 1
        }))

    };

    const handleStepChange = (data, step) => {
        setActiveStep((prevSteps) => ({
            ...prevSteps,
            [data?.propertyMaster_id]: step
        }));
    };

    const getUpparCaseName = (name) => {
        const words = name.split(" ");
        return words.map((word) => word[0].toUpperCase() + word.substring(1)).join(" ")
    }

    const getLastWordWithCapital = (sentence) => {
        const words = sentence.trim().split(" ");
        if (words?.length > 1) {
            const lastWord = words[words.length - 1];
            const capitalizedLastWord = lastWord.charAt(0).toUpperCase();
            words[words.length - 1] = capitalizedLastWord + ".";
        }
        return words.join(" ");
    }

    const capitalizeEachWord = (sentence) => {
        const words = sentence.trim().split(" ");
        const capitalizedWords = words.map(word =>
            word.charAt(0).toUpperCase() + word.slice(1)
        );
        return capitalizedWords.join(" ");
    }


    return (
        <div className="property-owner-list-details">
            {props.ListingDetailsWithOwner && props.ListingDetailsWithOwner.length > 0 && props.ListingDetailsWithOwner.map((item, index) => {
                return (
                    <DetailOwnerContainer>
                        <div className="assign-card">
                            <Row>
                                <Col xs={12} sm={5} md={5} lg={4} xl={4} xxl={4}>
                                    <div>
                                        <SwipeableViews
                                            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                            index={activeStep[item?.propertyMaster_id] || 0}
                                            onChangeIndex={(step) => handleStepChange(item, step)}
                                            enableMouseEvents
                                        >
                                            {item?.property_images.map((step, index) => {
                                                return (
                                                    <>
                                                        <div key={index}>
                                                            <CustomImage image={step?.media?.file} />
                                                        </div>

                                                    </>
                                                )
                                            })}
                                        </SwipeableViews>
                                        <MobileStepper
                                            position="static"
                                            variant="text"
                                            nextButton={
                                                <Button size="small" style={{ color: "white" }} onClick={() => handleNext(item, index)}>

                                                    {theme.direction === 'rtl' ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />}
                                                </Button>
                                            }
                                            backButton={
                                                <Button size="small" style={{ color: "white" }} onClick={() => handleBack(item, index)}>
                                                    {theme.direction === 'rtl' ? <ArrowForwardIosIcon /> : <ArrowBackIosIcon />}

                                                </Button>
                                            }
                                        />
                                    </div>
                                    {/* <div class="d-flex justify-content-center" style={{ paddingTop: "35%" }}>
                                        <div>
                                            <div className="rental-price">${item?.subscription_details?.price}<span style={{ fontSize: "16px", color: "#505050" }} >/month</span></div>
                                            <div className="rent-heading" >Rent</div>
                                        </div>
                                        <div><hr style={{ border: "1px solid black", height: "40px", marginLeft: "10px", marginTop: "20px" }} /></div>
                                        <div>
                                            <div className="deposit-price" style={{ marginLeft: "10px" }}>${item?.subscription_details?.deposit_amount}<span></span></div>
                                            <div className="rent-heading" style={{ marginLeft: "25px" }}>Deposit</div>
                                        </div>
                                    </div> */}

                                    <div class="d-flex justify-content-center rent-box">
                                        <div>
                                            <div className="rental-price">${item?.subscription_details?.price}<span style={{ fontSize: "16px", color: "#505050" }} >/{item?.subscription_details?.per_day === true ? "day" : "month"}</span></div>
                                            <div className="rent-heading" >Rent</div>
                                        </div>
                                        <div><hr style={{ border: "1px solid black", height: "40px", marginLeft: "10px", marginTop: "20px" }} /></div>
                                        <div>
                                            <div className="deposit-price" style={{ marginLeft: "10px" }}>${item?.subscription_details?.deposit_amount}<span></span></div>
                                            <div className="rent-heading" style={{ marginLeft: "25px" }}>Deposit</div>
                                        </div>
                                    </div>
                                    <div className="deposit-type">
                                        <div className="d-flex">
                                            <div className="rent-heading" >Rent : </div>
                                            <div className="rental-price">${item?.subscription_details?.price}<span style={{ fontSize: "14px", fontWeight: 400, color: "#505050" }} >{item?.subscription_details?.per_day === true ? "day" : "month"}</span></div>
                                        </div>
                                        <div className="d-flex">
                                            <div className="rent-heading">Deposit : </div>
                                            <div className="deposit-price">$<span>{item?.subscription_details?.deposit_amount}</span></div>

                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} sm={7} md={7} lg={8} xl={8} xxl={8}>
                                    <div className="property-detail-conatiner">
                                        <div className="d-flex justify-content-between section-profile" style={{ paddingTop: "10px" }}>
                                            <div className="d-flex">
                                                <div>
                                                    <img src={item?.Property_owner?.[0]?.profilePic} alt="" style={{ width: "50px", height: "50px", borderRadius: "50%" }} />
                                                </div>
                                                <div>
                                                    <div className="d-flex" style={{ paddingLeft: "7px" }}>
                                                        <div className="user-heading">{screenSize.width <= 575 ? item?.Property_owner?.[0]?.name?.length > 0 && capitalizeEachWord(getLastWordWithCapital(item?.Property_owner?.[0]?.name)) : item?.Property_owner?.[0]?.name?.length > 0 && getUpparCaseName(item?.Property_owner?.[0]?.name)}</div>
                                                        <div className="user-dot">.</div>
                                                        <div className="property-owner">Property Owner</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mobile-type">
                                            <div className="d-flex">
                                                <div className="rent-heading" >Rent : </div>
                                                <div className="rental-price">${item?.subscription_details?.price}<span style={{ fontSize: "14px", fontWeight: 400, color: "#505050" }} >{item?.subscription_details?.per_day === true ? "day" : "month"}</span></div>
                                            </div>
                                            <div className="d-flex" style={{ marginLeft: "10px" }}>
                                                <div className="rent-heading">Deposit : </div>
                                                <div className="deposit-price">$<span>{item?.subscription_details?.deposit_amount}</span></div>

                                            </div>
                                        </div>


                                        <div style={{ color: "#707070", fontSize: "16px", fontWeight: 500, marginTop: "5px" }}> {item?.location?.locality}</div>
                                        <Row className='d-flex' style={{ marginTop: "10px", borderBottom: "1px solid #B7B7B7", paddingBottom: "5px" }}>
                                            <Col xs={5} sm={5} md={5} lg={4} xl={3} xxl={3} className='d-flex'>
                                                <div><img src={Bedroom} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                <div className="categroy-heading">Bedroom</div>
                                                <div className="category-value">{item?.bedroomCount}</div>
                                            </Col>
                                            <Col xs={7} sm={7} md={7} lg={7} xl={6} xxl={6} className="d-flex">
                                                <div><img src={Bathroom} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                <div className="categroy-heading">Bathroom</div>
                                                <div className="category-value">{item?.propertyType?.name === "Room" ? item?.room_sharing?.name : item?.bathroomCount}</div>
                                            </Col>
                                        </Row>
                                        <div>
                                            <Row >
                                                <Col md={6} lg={4} xl={6} xxl={6}>
                                                    <div className="d-none d-sm-inline">
                                                        <div className="d-flex" style={{ marginTop: "10px" }} >
                                                            <div><img src={Clock} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                            <div className="categroy-heading">Available from</div>
                                                        </div>
                                                        <div className="select-value-heading">{item?.subscription_details?.propertyAvailfrom !== null && moment?.tz(item?.subscription_details?.propertyAvailfrom?.[0], item?.subscription_details?.propertyAvailfrom?.[0]).format("YYYY-MM-DD")}</div>
                                                    </div>
                                                </Col>

                                                {/* <Col md={6} lg={6} xl={6} xxl={6}>
                                                    <div className="d-flex align-items-center view-ipaid" style={{ cursor: "pointer", marginLeft: "7px", marginTop: "10px" }}>
                                                        <div className="view-heading" onClick={() => props.onClick(item?.propertyMaster_id, item?.Property_owner?.[0]?.name, item?.subscription_details?.propertyAvailfrom?.[0])}>View Details</div>
                                                        <div onClick={() => props.onClick(item?.propertyMaster_id, item?.Property_owner?.[0]?.name, item?.subscription_details?.propertyAvailfrom?.[0])}><img src={ArrowForward} alt="" style={{ width: "20px", height: "20px", marginLeft: "7px" }} /></div>
                                                    </div>
                                                </Col> */}

                                                <Col xs={5} sm={5} md={6} className="d-flex d-sm-none">
                                                    <div className="d-flex" style={{ marginTop: "10px" }} >
                                                        <div><img src={Clock} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                        <div className="categroy-heading">Available from</div>
                                                    </div>
                                                </Col>
                                                <Col xs={7} sm={7} md={6} className="d-flex d-sm-none">
                                                    <div className="select-value-heading">{item?.subscription_details?.propertyAvailfrom !== null && moment?.tz(item?.subscription_details?.propertyAvailfrom?.[0], item?.subscription_details?.propertyAvailfrom?.[0]).format("YYYY-MM-DD")}</div>
                                                </Col>
                                            </Row>
                                            <div className="d-flex justify-content-end" style={{ margin: screenSize.width > 575 && "0px 15px 15px 0" }} >
                                                <div className="view-heading" onClick={() => props.onClick(item?.propertyMaster_id, item?.Property_owner?.[0]?.name, item?.subscription_details?.propertyAvailfrom?.[0])}>View Details</div>
                                                <div style={{ marginLeft: "5px", cursor: "pointer" }} onClick={() => props.onClick(item?.propertyMaster_id, item?.Property_owner?.[0]?.name, item?.subscription_details?.propertyAvailfrom?.[0])}><img src={ArrowForward} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                    </DetailOwnerContainer>

                )
            })}
        </div>
    )
}

export default DetailWithOwnerList
