import React, { useEffect, useState } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import GovHome from '../../assets/images/GovHomeLogo.png'
import './Registration.scss'
import badge from '../../assets/icons/badge.png'
import call from '../../assets/icons/call.png'
import mail from '../../assets/icons/mail.png'
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { IMaskInput } from 'react-imask';
import { NumericFormat } from 'react-number-format';
import uploadImg from '../../assets/icons/upload_file.png';
import ContinueButton from '../../Components/Buttons/ContinueButton/ContinueButton';
import FormInput from '../../Components/Inputs/FormInput/FormInput';
import ChipInput from '../../Components/Inputs/ChipInput/ChipInput';
import BackButton from '../../Components/Buttons/BackButton/BackButton';
import { postMethod } from '../../utils/services/apis';
import { useDropzone } from "react-dropzone";
import UploadPhotoIcon from "../../assets/images/upload.svg"
import { toast } from 'react-toastify';
import url from '../../utils/services/urls.json';
import ArrowBack from "../../assets/images/arrow_back-ipad.svg"

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
            {...other}
            mask="+1 (#00) 000-0000"
            definitions={{
                '#': /[1-9]/,
            }}
            inputRef={ref}
            onAccept={(value) => onChange({ target: { name: props?.name, value } })}
            overwrite
        />
    );
});

TextMaskCustom.propTypes = {
    onChange: PropTypes.func.isRequired,
};

const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
    props,
    ref,
) {
    const { onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={(telephone) => {
                onChange({
                    target: {
                        name: props?.name,
                        value: telephone.value,
                    },
                });
            }}
            thousandSeparator
            valueIsNumericString
            prefix="$"
        />
    );
});

NumericFormatCustom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};
const Registration = () => {
    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    const [continueButton, setcontinueButton] = useState(false);
    const [name, setName] = useState('');
    const [nameError, setNameError] = useState(false);
    const [companyName, setCompanyName] = useState('');
    const [email, setEmail] = useState('');
    const [profileImageUpload, setProfileImageUpload] = useState([])
    const [profileUploadId, setProfileUploadId] = useState("")
    const [managerEmail, setManagerEmail] = useState("");
    const [ownerEmail, setOwnerEmail] = useState("")
    const [throughInvite, setThroughInvite] = useState(false)
    const [tags, setTags] = useState([])
    const [fileId, setfileId] = useState('')
    const [emailError, setEmailError] = useState('');

    const [telephone, setTelephone] = React.useState({
        Telephone: '',
    });

    const [isLoading, setIsLoading] = useState(false);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: '.jpg, .png, .jpeg',
        onDrop: (acceptedFiles) => {
            setIsLoading(true);
            let formData = new FormData();
            acceptedFiles.forEach((file) => {
                formData.append("file", file);
            });

            postMethod(url.profileImageUpload + "?query=profile", formData).then((response) => {
                if (response.status === 200 || response.status === 201) {
                    setProfileImageUpload(response?.data?.file);
                    setProfileUploadId(response?.data?.id);
                }
            })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    });

    const sendData = (data, file, filesId) => {
        setTags(data)
        filesId.map((id, index) => (
            setfileId(id)
        ))
    }
    console.log(tags)

    useEffect(() => {
        let validationEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email) &&
            (email.toLowerCase().endsWith('.gov') || email.toLowerCase().endsWith('@rootinfosol.com'))

        if (name.length && validationEmail && telephone.Telephone.length === 17) {
            setcontinueButton(true);
        } else {
            setcontinueButton(false);
        }
    }, [name, managerEmail ? managerEmail : email, telephone]);

    const getFormData = (event) => {
        event.preventDefault()
        const payloadProperty = {
            name: name,
            email: email,
            contact_number: telephone.Telephone,
            role: "user_role_property_manager",
            companyName: companyName,
            realEstateLicence: fileId,
            profilePic: profileUploadId
        };
        let body = {
            "manager_email": managerEmail,
            "owner_email": ownerEmail,
            "name": name,
            "contact_number": telephone.Telephone,
            "companyName": companyName,
            "realEstateLicence": fileId,
            "profilePic": profileUploadId

        }

        let validationEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(managerEmail ? managerEmail : email)
        if (throughInvite) {
            handlePropertyManagerRegistration(body)
        }
        else {
            if (name?.length && validationEmail && telephone?.Telephone.length === 17) {
                register(payloadProperty)
            }
        }
    };
    const handleTelephone = (event) => {
        setTelephone({
            ...telephone,
            ['Telephone']: event.target.value,
        });
    };

    const handleEmail = (event) => {
        setEmail(event.target.value);

        let validationEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(event.target.value);
        let isAllowedDomain = event.target.value.toLowerCase().endsWith('@rootinfosol.com') || event.target.value.toLowerCase().endsWith('.gov');

        if (!validationEmail || !isAllowedDomain) {
            if (validationEmail) {
                setEmailError("Please Provide Valid Approved Email .gov")
            } else {
                setEmailError('');
            }
        } else {
            setEmailError('');
        }
    };

    const isValidName = (input) => {
        const regex = /^[a-zA-Z\s_]+$/;
        return regex.test(input);
    };
    const handleNameChange = (e) => {
        const newName = e.target.value;
        setName(newName);
        setNameError(!isValidName(newName));
    };

    useEffect(() => {
        if (token !== null) {
            handleVerifyRegistration();
        }
    }, [])

    const handleVerifyRegistration = async () => {
        let body = {
            "token": token
        }
        await postMethod(url.verifyManager, body).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setManagerEmail(response.data.manager_email)
                setOwnerEmail(response.data.owner_email)
                setcontinueButton(true)
                setThroughInvite(true)
            }
        })
    }
    const handlePropertyManagerRegistration = async (body) => {
        if (managerEmail && ownerEmail) {
            await postMethod(url.managerSignup, body).then((response) => {
                if (response.status === 200 || response.status === 201) {
                    localStorage.setItem("ManagerId", response?.data?.ManagerId)
                    navigate(`/CreatePassword`)
                }
                else
                    toast.error(response?.data?.message);
            })
        }

    }

    const register = async (body) => {
        await postMethod(url.registerApi, body).then((response) => {
            let propertyValue = { heading: "Request Received", title: "We are reviewing your request. You’ll be notified when your request is approved." }
            if (response.status === 200 || response.status === 201) {
                toast.success(response?.data?.success)
                navigate(`/${params.value}/Registration/Success`, { state: propertyValue })
            } else if (profileUploadId === '') {
                toast.error("Please Upload Profile Image")
            } else if (fileId === "") {
                toast.error("Please Add Real State Licence")
            }
            else {
                toast.error(response?.data?.message)
            }
        })
    }

    return (
        <>
            <div className="registration">
                <div className="middleContent " >
                    <div>
                        <img className="arrow-back" src={ArrowBack} alt="" onClick={() => navigate(-1)} style={{ cursor: "pointer" }} />
                    </div>
                    <div className="logo">
                        <img className="logoImg" src={GovHome} alt="" />
                    </div>
                    <div className="mainFields p-2">
                        <h3 className='text-center typeHeding' style={{ fontWeight: 600, }}>Register Here</h3>
                        <div className='text-center' style={{ marginTop: "20px" }} >
                            <div>
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    {isDragActive ? (
                                        <p>Drop the files here ...</p>
                                    )
                                        :
                                        (
                                            <>
                                                {/* {profileImageUpload && profileImageUpload.length > 0 ?
                                                    (
                                                        <div>
                                                            <img style={{ width: "127px", height: "127px", border: "1px solid black", boxShadow: ' 1px 4px #bdbbbb', borderRadius: "50%" }} src={profileImageUpload} alt="" />
                                                        </div>
                                                    )
                                                    :
                                                    (
                                                        <div className="uploadPhoto">
                                                            <div className="uploadInfo">
                                                                <div style={{ marginTop: "30px" }}><img src={UploadPhotoIcon} alt="UploadPhotoIcon" /></div>
                                                                <div className="uploadText">Upload Image</div>
                                                            </div>
                                                        </div>
                                                    )
                                                } */}

                                                {isLoading ? (
                                                    <div className="uploadPhoto" >
                                                        <div className="uploadInfo">
                                                            <div className="loader"></div>
                                                        </div>
                                                    </div>
                                                ) : profileImageUpload && profileImageUpload.length > 0 ? (
                                                    <div>
                                                        <img
                                                            style={{
                                                                width: "127px",
                                                                height: "127px",
                                                                border: "1px solid black",
                                                                boxShadow: '1px 4px #bdbbbb',
                                                                borderRadius: "50%"
                                                            }}
                                                            src={profileImageUpload}
                                                            alt="Uploaded profile"
                                                        />
                                                    </div>
                                                ) : (
                                                    <div className="uploadPhoto">
                                                        <div className="uploadInfo">
                                                            <div style={{ marginTop: "30px" }}><img src={UploadPhotoIcon} /></div>
                                                            <div className="uploadText">Upload Image</div>
                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                        <form onSubmit={getFormData}>
                            <div className="get-regsister">
                                <FormInput error={nameError}
                                    helperText={nameError ? 'Invalid characters in name' : ''} required={true} name={'Name'} id={'name'} type={'text'} value={name} onChange={(e) => handleNameChange(e)} icon={`${badge}`} ></FormInput>
                                <Row >
                                    <Col sm={6} >
                                        <FormInput required={true} name={'Email'} id={'email'} type={'email'} value={managerEmail ? managerEmail : email} onChange={(e) => handleEmail(e)} icon={`${mail}`}></FormInput>

                                        {emailError && (
                                            <span style={{ color: 'red', fontSize: '12px', marginLeft: "10px" }}>{emailError}</span>
                                        )}
                                    </Col>
                                    <Col sm={6} >
                                        <FormInput required={true} name={'Telephone'} id={'telephone'} value={telephone.Telephone} onChange={handleTelephone} icon={`${call}`} inputComponent={TextMaskCustom}></FormInput>
                                    </Col>
                                </Row>
                                {params.value === 'propertyManager' &&
                                    <>
                                        <FormInput required={false} name={'Company Name (if any)'} id={'companyName'} type={'text'} value={companyName} onChange={(e) => setCompanyName(e.target.value)} ></FormInput>
                                        <ChipInput icon={uploadImg} sendData={sendData} />
                                    </>
                                }
                                <div >
                                    <ContinueButton className={continueButton && profileImageUpload && profileImageUpload.length > 0 && fileId !== "" ? "continue" : "continueDisabled"} backgroundColor={continueButton && profileImageUpload && profileImageUpload.length > 0 && fileId !== "" ? 'linear-gradient(180deg, #40A0E5 0%, #136BAB 100%)' : 'linear-gradient(180deg, #B4BDC4 0%, #919CA5 100%)'} disabled={!continueButton} text={'Continue'} height={'50px'} color={'#fff'} type='submit' />
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {/* <BackButton send={-1} text={'Register Here'} backText={'Back'}></BackButton> */}
            <BackButton className="back" backgroundColor='linear-gradient(180deg, #40A0E5 0%, #136BAB 100%)' text={'Back'} height={'50px'} color={'#fff'} width={"130px"} onClick={() => navigate(-1)} />
        </>
    )
}
export default Registration;





