import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import url from "../../../utils/services/urls.json"
import { getMethodWithToken } from '../../../utils/services/apis';
import PropertyOwnerAssignList from '../PropertyOwnerAssignList/PropertyOwnerAssignList';
import "./PropertyOwnerList.scss"
import { useNavigate } from 'react-router-dom';
import { navigateLogin } from '../../../utils/services/common';
import DefaultImage from "../../../assets/images/default-image.svg"
import { Col, Row } from 'react-bootstrap';
import DetailWithOwnerList from '../../../Components/commonComponents/CardDeatil/DetailWithOwnerList/DetailWithOwnerList';
import PhoneIcon from "../../../assets/images/listingManager/call.svg";
import Mail from "../../../assets/images/listingManager/mail.svg";
import ArrowBackChat from "../../../assets/images/arrow-back-chat.svg"

const PropertyList = styled.div`
margin-top: 20px;
width:100%;
`

const PropertyOwnerList = () => {
    const navigate = useNavigate()
    const [ownerList, setOwnerList] = useState([])
    const [searchListId, setSearchListId] = useState("")
    const [listDetail, setListDetail] = useState(false)
    const [selectValue, setSelectValue] = useState({})
    const [screenSize, getDimension] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    useEffect(() => {
        getPropertyOwner()
    }, [])

    const getPropertyOwner = async () => {
        await getMethodWithToken(url.getPropertyOwnerList).then((response) => {
            if (response?.status === 200 || response?.status === 201) {
                setOwnerList(response.data)
            } else if (response?.status === 401) {
                navigateLogin()
                navigate(`/propertyManager/Login`)
            }
        })
    }
    const handleListdetail = (value) => {
        setSearchListId(value.id)
        setSelectValue(value)
        setListDetail(true)
    }
    const getUpparCaseName = (name) => {
        const words = name.split(" ");
        return words.map((word) => word[0].toUpperCase() + word.substring(1)).join(" ")
    }
    const [ownerDetails, setOwnerDetails] = useState([])
    const [sliderImage, setSliderImage] = useState([])

    useEffect(() => {
        if (searchListId) {
            getPropertyOwnerDetail()
        }
    }, [searchListId])

    const getPropertyOwnerDetail = async () => {
        await getMethodWithToken(url.getPropertyDetail + searchListId + "/").then((response) => {
            if (response?.status === 200 || response?.status === 201) {
                setOwnerDetails(response?.data)
                if (response?.data?.length > 0) {
                    response?.data.map((value, index) => (
                        setSliderImage(value?.property_images)
                    ))
                }
            } else if (response?.status === 401) {
                navigateLogin()
                navigate(`/propertyManager/Login`)
            }
        })
    }

    const handleViewDetail = (id, userName, availableDate) => {
        navigate(`detail/${id}`, {
            state: {
                userName: userName,
                availableDate: availableDate
            }
        })
    }

    const handleArrowchange = () => {
        setListDetail(false)
        setSearchListId('')
    }
    return (
        <PropertyList>
            <div className="property-container">
                <Row >
                    {(!listDetail || screenSize.width > 1200) &&
                        <Col xs={12} sm={12} md={7} lg={7} xl={4} xxl={4} className="owner-list-card">
                            <div className="owner-main-heading">Property Owners</div>
                            <div className={ownerList?.length > 0 ? "property-owner-card" : "manager-card-without-result"} style={{ height: (ownerList?.length <= 7 && screenSize.width < 390) ? "80vh" : (ownerList?.length <= 7 && screenSize.width < 400) ? "81vh" : (ownerList?.length <= 7 && screenSize.width < 432) ? "83vh" : (ownerList?.length <= 7 && screenSize.width < 542) ? "80vh" : (ownerList?.length <= 7 && screenSize.width < 575) ? "83vh" : screenSize.width > 575 ? "400px" : "auto" }}>
                                {ownerList && ownerList.length > 0 ? ownerList.map((item, index) => {
                                    return (
                                        <div className="d-flex" style={{ borderBottom: "0.5px solid #D9D9D9", padding: "15px 0 5px 10px", cursor: "pointer", background: item?.property_owner?.id === searchListId ? "#D9EFFF" : "" }} onClick={() => handleListdetail(item?.property_owner)}>
                                            <div>
                                                <img src={item?.property_owner?.profilePic?.file ? item?.property_owner?.profilePic?.file : DefaultImage} alt="" style={{ width: "60px", height: "60px", borderRadius: "50%" }} />
                                            </div>
                                            <div className="users-heading" >
                                                <div className="user-main-heading" >{item?.property_owner?.name?.length > 0 && getUpparCaseName(item?.property_owner?.name)}</div>
                                            </div>
                                        </div>
                                    )
                                })
                                    :
                                    <div>No Property Owners List Found</div>
                                }
                            </div>
                        </Col>

                    }
                    <Col xs={12} sm={12} md={12} lg={12} xl={8} xxl={7} >
                        {listDetail &&
                            <div className="property-assign-container">
                                <div className="property-profile-seacrtion" >
                                    {screenSize.width < 1200 &&
                                        <img src={ArrowBackChat} alt="back" style={{ cursor: "pointer", marginLeft: "10px" }} onClick={handleArrowchange} />
                                    }
                                    <div className="d-flex justify-content-center">
                                        <img src={selectValue?.profilePic?.file ? selectValue?.profilePic?.file : DefaultImage} alt="" style={{ width: "80px", height: "80px", borderRadius: "50%" }} />
                                    </div>
                                    <div className="d-flex justify-content-center">
                                        <div className="property-owner-heading">{selectValue?.name?.length > 0 && getUpparCaseName(selectValue?.name)}</div>
                                    </div>
                                    <div className="phone-container" style={{ marginTop: "13px" }}>
                                        <div>
                                            <img src={PhoneIcon} alt="" />
                                        </div>
                                        <div className="phone-number">{ownerDetails?.[0]?.Property_owner?.[0]?.contact_number}</div>
                                        <div><hr style={{ border: "1px solid black", height: "20px", marginLeft: "10px", marginTop: "2px" }} /></div>
                                        <div style={{ marginLeft: "10px" }}>
                                            <img src={Mail} alt="" />
                                        </div>
                                        <div className="phone-number">{ownerDetails?.[0]?.Property_owner?.[0]?.email}</div>
                                    </div>
                                    <div className="email-conatiner mt-2">
                                        <div>
                                            <img src={PhoneIcon} alt="" className='phone-image' />
                                        </div>
                                        <div className="phone-number">{ownerDetails?.[0]?.Property_owner?.[0]?.contact_number}</div>
                                    </div>
                                    <div className="email-conatiner mt-2">
                                        <div style={{ marginLeft: "10px" }}>
                                            <img src={Mail} alt="" className='phone-image' />
                                        </div>
                                        <div className="phone-number">{ownerDetails?.[0]?.Property_owner?.[0]?.email}</div>
                                    </div>
                                </div>
                                <div className="list-assign-heading">Listings assigned to me</div>
                                <div>
                                    <DetailWithOwnerList ListingDetailsWithOwner={ownerDetails} sliderImage={sliderImage} showRequest={true} onClick={handleViewDetail} />
                                </div>
                            </div>
                        }
                    </Col>
                </Row>
            </div>
        </PropertyList>
    )
}

export default PropertyOwnerList
