import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import GovHome from '../../assets/images/GovHomeLogo.png'
import './Login.scss'
import { FormHelperText } from '@mui/material';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import mail from '../../assets/icons/mail.png'
import ContinueButton from '../../Components/Buttons/ContinueButton/ContinueButton';
import FormInput from '../../Components/Inputs/FormInput/FormInput';
import BackButton from '../../Components/Buttons/BackButton/BackButton';
import { postMethod } from '../../utils/services/apis';
import url from '../../utils/services/urls.json';
import { setParam, setToken, setManagerRole } from '../../utils/services/common';
import { toast } from "react-toastify";
import ArrowBack from "../../assets/images/arrow_back-ipad.svg"
import FormInputPassword from '../../Components/Inputs/FromInputPassword/FromInputPassword';

const LoginContainer = styled.div`
font-family: Proxima_nova_medium;
`

const Login = () => {
    const loginstyle = {
        fontFamily: "Proxima_nova_medium"
    }
    const params = useParams();
    const navigate = useNavigate();
    const [continueButton, setcontinueButton] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [errorFromApi, setErrorFromApi] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const [emailError, setEmailError] = useState('');

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const getFormData = (event) => {
        event.preventDefault()
        const payload = {
            email: email,
            password: password
        }
        if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email) && password.length) {
            Login(payload)
        }
    };
    const Login = async (body) => {
        await postMethod(url.loginApi, body).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setToken(response?.data?.key)
                setManagerRole(response?.data?.is_invited)
                setParam(params.value)
                toast.success(response.data.success)
                navigate("/Dashboard/AssignedListings")
            } else {
                setErrorFromApi(true)
                toast.error(response.data.error);
            }
        })
    }
    // useEffect(() => {
    //     if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email) && password.length >= 6) {
    //         setcontinueButton(true)
    //     } else {
    //         setcontinueButton(false)
    //     }
    //     setErrorFromApi(false)
    // }, [email, password])

    useEffect(() => {
        let validationEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email) &&
            (email.toLowerCase().endsWith('.gov') || email.toLowerCase().endsWith('@rootinfosol.com'))

        if (validationEmail && password.length >= 6) {
            setcontinueButton(true);
        } else {
            setcontinueButton(false);
        }
    }, [email, password]);

    const handleEmailChange = (event) => {
        setEmail(event.target.value);

        let validationEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(event.target.value);
        let isAllowedDomain = event.target.value.toLowerCase().endsWith('@rootinfosol.com') || event.target.value.toLowerCase().endsWith('.gov');

        if (!validationEmail || !isAllowedDomain) {
            if (validationEmail) {
                // setEmailError('Email must end with @rootinfosol.com or .gov');
                setEmailError("Please Provide Valid Approved Email .gov")
            } else {
                setEmailError('');
            }
        } else {
            setEmailError('');
        }
    }

    useEffect(() => {
        setParam(params.value);
    }, []);

    const handleForgotPassword = () => {
        navigate(`/${params.value}/ForgotPassword`)
    }
    return (
        <>
            {/* <div className="login" style={loginstyle}>
                <div className="middleContent " >
                    <div>
                        <img className="arrow-back" src={ArrowBack} alt="" onClick={() => navigate(-1)} style={{ cursor: "pointer" }} />
                    </div>
                    <div className="logo">
                        <img className="logoImg" src={GovHome} alt="" />
                    </div>
                    <div className="mainFields p-2">
                        <h3 className='text-center typeHeding' style={{ fontFamily: 'Proxima_nova_medium' }}>Login</h3>
                        <form onSubmit={getFormData}>
                            <FormInput required={true} name={'Email'} id={'email'} type={'email'} value={email} onChange={(e) => setEmail(e.target.value)} icon={`${mail}`}></FormInput>
                            <FormInput required={true} name={'Password'} id={'password'} type={showPassword ? 'text' : 'password'} value={password} onChange={(e) => setPassword(e.target.value)} showIcon={showPassword ? <VisibilityOff /> : <Visibility />}
                                onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} ></FormInput>
                            <FormHelperText className='text-danger' id="email" style={{ padding: '0 8px' }}>{errorFromApi ? 'Incorrect email or password. Please try again.' : ''}</FormHelperText>
                            <div className="d-flex justify-content-end mt-3" >
                                <div onClick={handleForgotPassword} style={{ color: 'var(--primary)', cursor: 'pointer' }}>Forgot Password?</div>
                            </div>
                            <div className="login-button" >
                                <ContinueButton className={continueButton ? "continue" : "continueDisabled"} backgroundColor={continueButton ? 'linear-gradient(180deg, #40A0E5 0%, #136BAB 100%)' : 'linear-gradient(180deg, #B4BDC4 0%, #919CA5 100%)'} disabled={!continueButton} text={'Login'} height={'50px'} color={'#fff'} />
                            </div>
                        </form>
                    </div>
                </div>
            </div> */}

            <div className="login" style={loginstyle}>
                <div className="middleContent " >
                    <div>
                        <img className="arrow-back" src={ArrowBack} alt="" onClick={() => navigate(-1)} style={{ cursor: "pointer" }} />
                    </div>
                    <div className="logo">
                        <img className="logoImg" src={GovHome} alt="" />
                    </div>

                    <div className="mainFields">
                        <h3 className='text-center typeHeding' style={{ fontFamily: 'Proxima_nova_medium' }}>Login</h3>
                        <form onSubmit={getFormData} className="get-regsister">
                            <FormInput required={true} name={'Email'} id={'email'} type={'email'} value={email} onChange={(e) => handleEmailChange(e)} icon={`${mail}`}></FormInput>
                            {emailError && (
                                <span style={{ color: 'red', fontSize: '12px', marginLeft: "10px" }}>{emailError}</span>
                            )}
                            <FormInputPassword required={true} name={'Password'} id={'password'} type={showPassword ? 'text' : 'password'} value={password} onChange={(e) => setPassword(e.target.value)} showIcon={showPassword ? < Visibility /> : < VisibilityOff />}
                                onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} ></FormInputPassword>
                            <FormHelperText className='text-danger' id="email" style={{ padding: '0 8px' }}>{errorFromApi ? 'Incorrect email or password. Please try again.' : ''}</FormHelperText>
                            <div className="d-flex justify-content-end mt-3" >
                                <div onClick={handleForgotPassword} style={{ color: 'var(--primary)', cursor: 'pointer' }}>Forgot Password?</div>
                            </div>
                            <div className="login-button">
                                <ContinueButton className={continueButton ? "continue" : "continueDisabled"} backgroundColor={continueButton ? 'linear-gradient(180deg, #40A0E5 0%, #136BAB 100%)' : 'linear-gradient(180deg, #B4BDC4 0%, #919CA5 100%)'} disabled={!continueButton} text={'Login'} height={'50px'} color={'#fff'} />
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            {/* <BackButton send={-1} text={'Login'} backText={'Back'}></BackButton> */}
            <BackButton className="back" backgroundColor='linear-gradient(180deg, #40A0E5 0%, #136BAB 100%)' text={'Back'} height={'50px'} color={'#fff'} width={"130px"} onClick={() => navigate(-1)} />
        </>
    )
}
export default Login;
