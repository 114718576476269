import React, { useEffect, useState } from 'react'
import call from '../../assets/icons/call.png'
import mail from '../../assets/icons/mail.png'
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { IMaskInput } from 'react-imask';
import { NumericFormat } from 'react-number-format';
import uploadImg from '../../assets/icons/upload_file.png';
import ContinueButton from '../../Components/Buttons/ContinueButton/ContinueButton';
import FormInput from '../../Components/Inputs/FormInput/FormInput';
import ChipInput from '../../Components/Inputs/ChipInput/ChipInput';
import { getMethodWithToken, postMethodWithToken, putMethodWithToken } from '../../utils/services/apis';
import url from '../../utils/services/urls.json';
import { useDropzone } from "react-dropzone";
import styled from 'styled-components';
import "./UserProfile.scss"
import ProfileIcon from "../../assets/images/profile.svg";
import { useNavigate } from 'react-router-dom';
import { navigateLogin } from '../../utils/services/common';

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
            {...other}
            mask="+1 (#00) 000-0000"
            definitions={{
                '#': /[1-9]/,
            }}
            inputRef={ref}
            onAccept={(value) => onChange({ target: { name: props?.name, value } })}
            overwrite
        />
    );
});

TextMaskCustom.propTypes = {
    onChange: PropTypes.func.isRequired,
};

const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
    props,
    ref,
) {
    const { onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={(telephone) => {
                onChange({
                    target: {
                        name: props?.name,
                        value: telephone.value,
                    },
                });
            }}
            thousandSeparator
            valueIsNumericString
            prefix="$"
        />
    );
});

NumericFormatCustom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const MainDiv = styled.div`
font-family: Proxima_nova_reg;
// width:900px;
background: white;
padding: 20px 40px 0 40px;
border: 1px solid #C7C7C7;
margin-top: 20px;
box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

// @media only screen and (min-width:1100px) and (max-width: 1300px) {
//     width:700px;
//   }
//   @media only screen and (max-width: 1150px) {
//     width:600px;
//   }
`;
const UserProfile = () => {
    const navigate = useNavigate()
    const [continueButton, setcontinueButton] = useState(false);
    const [name, setName] = useState('');
    const [address, setAddress] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [addressError, setAddressError] = useState(false)
    const [companyName, setCompanyName] = useState('');
    const [email, setEmail] = useState('');
    const [profileEmail, setProfileEmail] = useState()
    const [contactNumber, setContactNumber] = useState('')
    const [profileImageUpload, setProfileImageUpload] = useState('')
    const [profileUploadId, setProfileUploadId] = useState("")
    const [tags, setTags] = useState([])
    const [fileId, setfileId] = useState('');
    const [fileName, setFileName] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const sendData = (data, file, filesId) => {
        setTags(data)
        filesId.map((id, index) => (
            setfileId(id)
        ))
    }
    // console.log(tags)
    useEffect(() => {
        let validationEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)
        if (address?.length && validationEmail && contactNumber?.length === 17 && companyName?.length) {
            setcontinueButton(true)
        } else {
            setcontinueButton(false)
        }

    }, [address, email, contactNumber, companyName])

    useEffect(() => {
        getProfile();
    }, []);

    const getProfile = async () => {
        setFileName([{ name: "" }])
        await getMethodWithToken(url.userProfile).then((response) => {
            if (response?.status === 200 || response?.status === 201) {
                localStorage.setItem("Id", response.data.id)
                setName(response.data.name && response?.data?.name)
                setPhoneNumber(response.data.contact_number && response?.data?.contact_number)
                setContactNumber(response.data.contact_number && response?.data?.contact_number)
                setEmail(response?.data?.email)
                setProfileEmail(response?.data?.email)
                setProfileImageUpload(response?.data?.profilePic?.file)
                setCompanyName(response?.data?.companyName)
                setfileId(response?.data?.realEstateLicence?.id);
                fileName.push({ name: response?.data?.realEstateLicence?.name })
                setAddress(response?.data?.address)
                setProfileUploadId(response?.data?.profilePic?.id)
            } else if (response?.status === 401) {
                navigateLogin()
                navigate(`/propertyManager/Login`)
            }
        })
    }

    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, } = useDropzone({
        accept: '.jpg, .png, .jpeg',
        onDrop: (acceptedFile) => {
            setIsLoading(true);
            let formData = new FormData();
            acceptedFile.map((file, index) => {
                return (
                    formData.append("file", file)
                )
            });
            postMethodWithToken(url.profileImageUpload + "?query=profile", formData).then((response) => {
                if (response.status === 200 || response.status === 201) {
                    setProfileUploadId(response?.data?.id)
                    setProfileImageUpload(response?.data?.file)
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
        }
    });

    const handleSubmitProfile = async (event) => {
        event.preventDefault();
        let body = {
            name: name,
            address: address,
            contact_number: contactNumber,
            companyName: companyName,
            profilePic: profileUploadId,
            realEstateLicence: +fileId
        }

        await putMethodWithToken(url.updateManagerProfile, body).then((response) => {
            if (response.status === 200 || response.status === 201) {
                getProfile()
            }
        })
    }

    const handleEmail = (e) => {
        const { value } = e.target;
        setEmail(value);
    };

    const isValidName = (input) => {
        const regex = /^[a-zA-Z\s_]+$/;
        return regex.test(input);
    };

    const handleAddressChange = (e) => {
        const newAddress = e.target.value;
        setAddress(newAddress);
        setAddressError(!isValidName(newAddress));
    };
    const handleTelephone = (event) => {
        setContactNumber(event.target.value)
    };

    const getUpparCaseName = (name) => {
        const words = name.split(" ");
        return words.map((word) => word[0].toUpperCase() + word.substring(1)).join(" ")
    }

    return (
        <Row>
            <Col>
                <MainDiv>
                    <div className='text-center' style={{ borderBottom: '1px solid #EAEAEA', paddingBottom: '24px' }}>
                        <div className="ppCircle">
                            {/* <img style={{ width: "127px", height: "127px", border: "1px solid black", boxShadow: ' 1px 4px #bdbbbb' }} src={profileImageUpload !== null && profileImageUpload} alt="" /> */}
                            {isLoading ?
                                <div className="loader-container" >
                                    <div className="loader"></div>
                                </div>
                                :
                                <img style={{ width: "127px", height: "127px", border: "1px solid black", boxShadow: ' 1px 4px #bdbbbb' }} src={profileImageUpload !== null && profileImageUpload} alt="" />
                            }
                            <div {...getRootProps()} style={{ border: "none" }}>
                                <input {...getInputProps()} />
                                {isDragAccept && <p>All files will be accepted</p>}
                                {isDragReject && <p>Some files will be rejected</p>}
                                {!isDragActive && (
                                    <div className="editBtn d-flex justify-content-center" style={{ cursor: "pointer" }}>
                                        <img src={ProfileIcon} alt="edit" className="align-self-center" />
                                    </div>
                                )}
                            </div>

                            <div className='main-heading text-center'>{name?.length > 0 && getUpparCaseName(name)}</div>
                            <div className="text d-none d-sm-flex justify-content-center" style={{ color: '#919CA5' }}>
                                <div className="d-flex align-items-center">
                                    <img src={call} alt="" style={{ maxWidth: '18px', maxHeight: '18px' }} />
                                    <div className="main-text">{phoneNumber}</div>
                                </div>
                                <div className="box d-flex align-items-center" style={{ marginLeft: '24px' }}>
                                    <img src={mail} alt="" style={{ maxWidth: '18px', maxHeight: '18px' }} />
                                    <div className="main-text">{profileEmail}</div>
                                </div>
                            </div>
                            <div className="d-sm-none mt-2" style={{ color: '#919CA5' }}>
                                <div className="d-flex align-items-center justify-content-center">
                                    <img src={call} alt="" style={{ maxWidth: '18px', maxHeight: '18px' }} />
                                    <div className="main-text">{phoneNumber}</div>
                                </div>
                                <div className="box d-flex align-items-center justify-content-center">
                                    <img src={mail} alt="" style={{ maxWidth: '18px', maxHeight: '18px' }} />
                                    <div className="main-text">{profileEmail}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <form className="input-fields">
                        <div className='name_field'>
                            <FormInput error={addressError}
                                helperText={addressError ? 'Invalid characters in name' : ''} required={true} name={'Address'} id={'Address'} type={'text'} value={address} onChange={(e) => handleAddressChange(e)} ></FormInput>
                        </div>
                        <Row >
                            <Col sm={6} >
                                <FormInput required={true} name={'Email'} id={'email'} type={'email'} value={email}
                                    onChange={(e) => handleEmail(e)} icon={mail}></FormInput>
                            </Col>
                            <Col sm={6} >
                                <FormInput required={true} name={'Telephone'} id={'telephone'} value={contactNumber} onChange={handleTelephone} icon={call} inputComponent={TextMaskCustom}></FormInput>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <FormInput required={false} name={'Company Name (if any)'} id={'companyName'} type={'text'} value={companyName} onChange={(e) => setCompanyName(e.target.value)} ></FormInput>
                            </Col>
                            <Col sm={12}>
                                <ChipInput icon={uploadImg} sendData={sendData} fileName={fileName} />
                            </Col>
                        </Row>
                        <div className="button-login">
                            <ContinueButton backgroundColor={name && name.length > 0 && email && email.length > 0 && contactNumber && contactNumber.length > 0 ? 'linear-gradient(180deg, #40A0E5 0%, #136BAB 100%)' : 'linear-gradient(180deg, #B4BDC4 0%, #919CA5 100%)'} disabled={continueButton} text={'Save Changes'} height={'50px'} color={'#fff'} onClick={(e) => handleSubmitProfile(e)} className="savechanges_button" />
                        </div>
                    </form>
                </MainDiv>
            </Col>
        </Row>
    )
}

export default UserProfile
